var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('h4',{staticClass:"mb-0"},[_vm._v(" Edit Kompetensi Spiritual ")]),_c('validation-observer',{ref:"validUpdate"},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"NIP","label-for":"nip"}},[_c('validation-provider',{attrs:{"name":"NIP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nip","state":errors.length > 0 ? false : null,"placeholder":"NIP"},model:{value:(_vm.dataStudent.nip),callback:function ($$v) {_vm.$set(_vm.dataStudent, "nip", $$v)},expression:"dataStudent.nip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nama Lengkap","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Nama Lengkap","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"placeholder":"Nama Lengkap"},model:{value:(_vm.dataStudent.name),callback:function ($$v) {_vm.$set(_vm.dataStudent, "name", $$v)},expression:"dataStudent.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nama Panggilan","label-for":"nickname"}},[_c('validation-provider',{attrs:{"name":"Nama Panggilan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nickname","state":errors.length > 0 ? false : null,"placeholder":"Nama Panggilan"},model:{value:(_vm.dataStudent.nickname),callback:function ($$v) {_vm.$set(_vm.dataStudent, "nickname", $$v)},expression:"dataStudent.nickname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Gelar Awal","label-for":"first_title"}},[_c('validation-provider',{attrs:{"name":"Gelar Awal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_title","state":errors.length > 0 ? false : null,"placeholder":"Gelar Awal"},model:{value:(_vm.dataStudent.first_title),callback:function ($$v) {_vm.$set(_vm.dataStudent, "first_title", $$v)},expression:"dataStudent.first_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Gelar Akhir","label-for":"last_title"}},[_c('validation-provider',{attrs:{"name":"Gelar Akhir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last_title","state":errors.length > 0 ? false : null,"placeholder":"Gelar Akhir"},model:{value:(_vm.dataStudent.last_title),callback:function ($$v) {_vm.$set(_vm.dataStudent, "last_title", $$v)},expression:"dataStudent.last_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Jenis Kelamin","label-for":"gender"}},[_c('validation-provider',{attrs:{"name":"Jenis Kelamin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"gender","reduce":function (gender) { return gender.value; },"options":_vm.dataGender,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text","placeholder":"Pilih Jenis Kelamin"},model:{value:(_vm.dataStudent.gender),callback:function ($$v) {_vm.$set(_vm.dataStudent, "gender", $$v)},expression:"dataStudent.gender"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tempat Lahir","label-for":"birth_place"}},[_c('validation-provider',{attrs:{"name":"Tempat Lahir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"birth_place","state":errors.length > 0 ? false : null,"placeholder":"Yogyakarta"},model:{value:(_vm.dataStudent.birth_place),callback:function ($$v) {_vm.$set(_vm.dataStudent, "birth_place", $$v)},expression:"dataStudent.birth_place"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tanggal Lahir","label-for":"birth_date"}},[_c('validation-provider',{attrs:{"name":"Tanggal Lahir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"birth_date","state":errors.length > 0 ? false : null,"placeholder":"Tanggal Lahir","type":"date"},model:{value:(_vm.dataStudent.birth_date),callback:function ($$v) {_vm.$set(_vm.dataStudent, "birth_date", $$v)},expression:"dataStudent.birth_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Pendidikan Terakhir","label-for":"education_level"}},[_c('validation-provider',{attrs:{"name":"Pendidikan Terakhir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"education_level","reduce":function (education_level) { return education_level.value; },"options":_vm.dataEducation,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text","placeholder":"Pilih Pendidikan Terakhir"},model:{value:(_vm.dataStudent.education_level),callback:function ($$v) {_vm.$set(_vm.dataStudent, "education_level", $$v)},expression:"dataStudent.education_level"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nama Institusi","label-for":"educational_institution"}},[_c('validation-provider',{attrs:{"name":"Nama Institusi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"educational_institution","state":errors.length > 0 ? false : null,"placeholder":"Nama Institusi"},model:{value:(_vm.dataStudent.educational_institution),callback:function ($$v) {_vm.$set(_vm.dataStudent, "educational_institution", $$v)},expression:"dataStudent.educational_institution"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"KTP","label-for":"ktp_number"}},[_c('validation-provider',{attrs:{"name":"KTP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ktp_number","state":errors.length > 0 ? false : null,"placeholder":"KTP"},model:{value:(_vm.dataStudent.ktp_number),callback:function ($$v) {_vm.$set(_vm.dataStudent, "ktp_number", $$v)},expression:"dataStudent.ktp_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Agama","label-for":"religion"}},[_c('validation-provider',{attrs:{"name":"Agama","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"religion","reduce":function (religion) { return religion.value; },"options":_vm.dataReligion,"label":"text","placeholder":"Pilih Agama"},model:{value:(_vm.dataStudent.religion),callback:function ($$v) {_vm.$set(_vm.dataStudent, "religion", $$v)},expression:"dataStudent.religion"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-form-group',{attrs:{"label":"Kewarganegaraan","label-for":"nationality"}},[_c('validation-provider',{attrs:{"name":"Kewarganegaraan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"nationality","reduce":function (nationality) { return nationality.name; },"options":_vm.dataCountries,"label":"name","placeholder":"Pilih Kewarganegaraan"},model:{value:(_vm.dataStudent.nationality),callback:function ($$v) {_vm.$set(_vm.dataStudent, "nationality", $$v)},expression:"dataStudent.nationality"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-form-group',{attrs:{"label":"Status Menikah","label-for":"marital_status"}},[_c('validation-provider',{attrs:{"name":"marital_status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"marital_status","reduce":function (marital_status) { return marital_status.name; },"options":_vm.dataMaritalStatus,"label":"name","placeholder":"Pilih Status Menikah"},model:{value:(_vm.dataStudent.marital_status),callback:function ($$v) {_vm.$set(_vm.dataStudent, "marital_status", $$v)},expression:"dataStudent.marital_status"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-form-group',{attrs:{"label":"Aktif","label-for":"is_active"}},[_c('validation-provider',{attrs:{"name":"is_active","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"is_active","reduce":function (is_active) { return is_active.name; },"options":_vm.dataIsActive,"label":"name","placeholder":"Pilih Aktif / Tidak Aktif"},model:{value:(_vm.dataStudent.is_active),callback:function ($$v) {_vm.$set(_vm.dataStudent, "is_active", $$v)},expression:"dataStudent.is_active"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tipe Pegawai","label-for":"type"}},[_c('validation-provider',{attrs:{"name":"Tipe Pegawai","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"type","state":errors.length > 0 ? false : null,"placeholder":"Tipe Pegawai"},model:{value:(_vm.dataStudent.type),callback:function ($$v) {_vm.$set(_vm.dataStudent, "type", $$v)},expression:"dataStudent.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"Provinsi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Provinsi","label-for":"province","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"province","placeholder":"Pilih Provinsi","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.dataProvinces,"label":"name"},model:{value:(_vm.dataStudent.province),callback:function ($$v) {_vm.$set(_vm.dataStudent, "province", $$v)},expression:"dataStudent.province"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Kabupaten/Kota","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Kabupaten/Kota","label-for":"city","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"city","placeholder":"Pilih Kabupaten/Kota","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.filterCities,"label":"name"},model:{value:(_vm.dataStudent.city),callback:function ($$v) {_vm.$set(_vm.dataStudent, "city", $$v)},expression:"dataStudent.city"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Kecamatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Kecamatan","label-for":"district","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"district","placeholder":"Pilih Kecamatan","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.filterDistrics,"label":"name"},model:{value:(_vm.dataStudent.district),callback:function ($$v) {_vm.$set(_vm.dataStudent, "district", $$v)},expression:"dataStudent.district"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"RW","label-for":"rw"}},[_c('validation-provider',{attrs:{"name":"RW","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rw","state":errors.length > 0 ? false : null,"placeholder":"RW"},model:{value:(_vm.dataStudent.rw),callback:function ($$v) {_vm.$set(_vm.dataStudent, "rw", $$v)},expression:"dataStudent.rw"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"RT","label-for":"rt"}},[_c('validation-provider',{attrs:{"name":"RT","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rt","state":errors.length > 0 ? false : null,"placeholder":"RT"},model:{value:(_vm.dataStudent.rt),callback:function ($$v) {_vm.$set(_vm.dataStudent, "rt", $$v)},expression:"dataStudent.rt"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Alamat","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"Alamat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","state":errors.length > 0 ? false : null,"placeholder":"Alamat"},model:{value:(_vm.dataStudent.address),callback:function ($$v) {_vm.$set(_vm.dataStudent, "address", $$v)},expression:"dataStudent.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"No. Handphone 1","label-for":"phone_1"}},[_c('validation-provider',{attrs:{"name":"No. Handphone 1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone_1","state":errors.length > 0 ? false : null,"placeholder":"No. Handphone 1"},model:{value:(_vm.dataStudent.phone_1),callback:function ($$v) {_vm.$set(_vm.dataStudent, "phone_1", $$v)},expression:"dataStudent.phone_1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"No. Handphone 2","label-for":"phone_2"}},[_c('validation-provider',{attrs:{"name":"No. Handphone 2","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone_2","state":errors.length > 0 ? false : null,"placeholder":"No. Handphone 2"},model:{value:(_vm.dataStudent.phone_2),callback:function ($$v) {_vm.$set(_vm.dataStudent, "phone_2", $$v)},expression:"dataStudent.phone_2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Whatsapp","label-for":"whatsapp"}},[_c('validation-provider',{attrs:{"name":"Whatsapp","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"whatsapp","state":errors.length > 0 ? false : null,"placeholder":"Whatsapp"},model:{value:(_vm.dataStudent.whatsapp),callback:function ($$v) {_vm.$set(_vm.dataStudent, "whatsapp", $$v)},expression:"dataStudent.whatsapp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"text-right"},[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function () { return _vm.$router.go(-1); }}},[_vm._v(" Cancel ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Update ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }